import { checkResponse, paramsFromQuery, flomV1 } from 'core/api';
import { productTypes } from '../const/productConst';

export const getProductsList = async ({ queryKey }) => {
  const response = await flomV1.get('/api/v2/products', {
    params: paramsFromQuery({ ...queryKey[1], type: productTypes.product.value }),
  });
  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const getProductById = async ({ queryKey }) => {
  const { id } = queryKey[1];
  const response = await flomV1.post('/api/v2/product/getbyid', {
    productId: id,
  });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const patchProductStatus = async ({ id, status, comment }) => {
  const response = await flomV1.patch(`/api/v2/products/moderation/${id}`, {
    moderationStatus: status,
    comment,
  });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const getVideosList = async ({ queryKey }) => {
  const response = await flomV1.get('/api/v2/products', {
    params: paramsFromQuery({ ...queryKey[1], type: productTypes.video.value }),
  });
  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const getPodcastsList = async ({ queryKey }) => {
  const response = await flomV1.get('/api/v2/products', {
    params: paramsFromQuery({ ...queryKey[1], type: productTypes.podcast.value }),
  });
  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const getTextStoriesList = async ({ queryKey }) => {
  const response = await flomV1.get('/api/v2/products', {
    params: paramsFromQuery({ ...queryKey[1], type: productTypes.textStory.value }),
  });
  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const getVideoStoriesList = async ({ queryKey }) => {
  const response = await flomV1.get('/api/v2/products', {
    params: paramsFromQuery({ ...queryKey[1], type: productTypes.expo.value }),
  });
  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const getTribeById = async ({ queryKey }) => {
  const { tribeId } = queryKey[1];

  const response = await flomV1.get(`/api/v2/tribes/${tribeId}`);

  if (checkResponse(response)) {
    return response.data.data.tribe;
  }
};

const postFeaturedProduct = async (productId, value) => {
  let countryCode = typeof value !== 'boolean' ? value : null;
  const response = await flomV1.post(`/api/v2/product/featured/${productId}`, {
    ...(countryCode && { countryCode: value }),
  });

  if (checkResponse(response)) {
    return response.data.data.product;
  }
};

const deleteFeaturedProduct = async (productId) => {
  const response = await flomV1.delete(`/api/v2/product/featured/${productId}`);

  if (checkResponse(response)) {
    return response.data.data.product;
  }
};

export const updateFeaturedProduct = async (productId, value) => {
  if (value) {
    return await postFeaturedProduct(productId, value);
  }
  return await deleteFeaturedProduct(productId);
};

const convertToFormData = (values) => {
  let formData = new FormData();

  for (let key in values) {
    if (values[key] !== undefined) {
      formData.append(key, values[key]);
    }
  }
  return formData;
};

export const editContent = async (contentId, changedContent, type) => {
  console.log({ contentId, changedContent });

  const changedContentForApi = {};
  for (const [key, value] of Object.entries(changedContent)) {
    console.log(`${key}: ${value}`);
    if (value === true) {
      changedContentForApi[key] = 1;
    } else if (value === false) {
      changedContentForApi[key] = 0;
    } else {
      changedContentForApi[key] = value;
    }
  }

  if (type === productTypes.product.value) {
    const response = await flomV1.patch(
      '/api/v2/big/product/edit/new',
      convertToFormData({ ...changedContentForApi, productId: contentId })
    );

    if (checkResponse(response)) {
      return response.data.data;
    }
  }

  const response = await flomV1.patch(
    '/api/v2/big/products/' + contentId + '/new',
    convertToFormData(changedContentForApi)
  );

  if (checkResponse(response)) {
    return response.data.data.product;
  }
};
